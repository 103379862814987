<template>
  <section id="content" style="margin-bottom: 80px">
    <v-card
      class="mx-auto py-1 mb-2"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Birding
          </v-card-title>
          <v-card-text
            class="text-left text-center subtitle-1 font-weight-light mx-auto mb-5"
          >
            Southern Zululand is home to a great diversity of species, with the
            town of Mtunzini offering a wealth of treasures for any birding
            enthusiast. Its diverse vegetation of swamp and coastal forests,
            estuarine mudflats, mangroves, and open grasslands, all protected
            and upheld by the Mtunzini Conservation Committee, provide habitats
            for over 480 species of birds. On a walk along the paths at
            Twinstreams, a forest birder is likely to spot species like the
            Yellow-bellied Greenbul, Narina Trogon, Square-tailed Drongo,
            Spotted Ground-Thrush, Gorgeous Bush-Shrike, Black-bellied Starling
            or Dark-backed Weaver. <br /><br />If you are lucky you may even be
            able to spot some of the rare or endangered birds the area offers
            such as the Green Barbet, the threatened Spotted Ground-Thrush,
            Eastern Bronzenaped Pigeons, Crowned Eagles, Trumpeter hornbills,
            Woolly-necked Storks, Green Twinspots, or the Mangrove Kingfisher.
            The town is also home to one of the rarest raptors in South Africa,
            the Palm-nut Vulture. This is owing to the abundance of Raphia palms
            growing in the area, whose orange palm fruits form a vital part of
            the vulture’s diet. Raphia palms are proudly one of Twinstreams
            signature trees. We believe through education and promotion we could
            begin to see these beautiful indigenous trees being planted in other
            areas of South Africa, hopefully bringing with them the glorious
            Palm-nut Vulture. <br /><br />
            If you’re an avid birder, then pack your binoculars and bird book
            and hit the paths at Twinstreams. Click on the link to print off a
            list of birds that have been found in the area and tick them off as
            you go. Happy birding!
          </v-card-text>

          <div class="text-center">
            <p>
              <v-btn ma-4 color="thirdley" @click="ShowMenuClicked = true">
                Bird Check List PDF &nbsp;&nbsp;
                <span style="padding-left: 3px; font-size: 16px;">
                  <font-awesome-icon right :icon="['fas', 'book-open']" />
                </span>
              </v-btn>
            </p>
          </div>

          <v-overlay absolute :value="ShowMenuClicked">
            <v-col cols="10">
              <iframe
                v-if="ShowMenuClicked"
                src="/static/checklist.pdf"
                :height="[$vuetify.breakpoint.smAndUp ? '700' : '400']"
                :width="[$vuetify.breakpoint.smAndUp ? '900' : '250']"
              />
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="ShowMenuClicked = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-overlay>
          <div class="text-center">
            <p>
              <a
                style="text-decoration: none;"
                href="https://avibase.bsc-eoc.org/checklist.jsp?region=ZAkn28"
              >
                <v-btn> Mtunzini bird list -Avi base </v-btn>
              </a>
            </p>
          </div>

          <!-- <div class="text-center">
            <p>
              <a
                style="text-decoration: none;"
                href="https://www.inaturalist.org/check_lists/51211-Mtunzini-Check-List?iconic_taxon=3"
              >
                <v-btn> Mtunzini bird list -inaturalist </v-btn>
              </a>
            </p>
          </div> -->
        </v-container>

        <v-card
          class="mb-2 mx-auto"
          height="70vh"
          width="80vw"
          min-height="550px"
          tile
          max-width="100%"
        >
          <v-img
            :src="require('@/assets/Main/birding/palmnut-vulture.webp')"
            cycle
            height="70vh"
            min-height="550px"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          />
        </v-card>

        <section class="mx-auto mt-5 pt-5 pb-0 mb-2">
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",

  data: () => ({
    ShowMenuClicked: false,
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      [
        "https://www.facebook.com/blockhousekitchen.constantia/",
        "mdi-facebook",
        "Facebook"
      ],
      [
        "https://www.instagram.com/blockhousekitchen/",
        "mdi-instagram",
        "Instagram"
      ],
      ["https://blockhousekitchen.co.za/", "mdi-web", "Website"]
    ]
  })
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
</style>
